import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, description, ogImage }) => {
  return (
    <>
      {title && <Title title={title} />}
      {description && <Desc desc={description} />}
      {ogImage && <Image ogImage={ogImage} />}
    </>
  )
}

const Image = ({ ogImage }) => (
  <Helmet>
    <meta key='og_image' property='og:image' content={ogImage} />
  </Helmet>
)
const Desc = ({ desc }) => (
  <Helmet>
    <meta key='description' name='description' content={desc} />
    <meta key='og_description' property='og:description' content={desc} />
  </Helmet>
)
const Title = ({ title }) => (
  <Helmet>
    {title && <title key='title'>{title}</title>}
    {title && <meta key='og_title' property='og:title' content={title} />}
  </Helmet>
)
// taken from https://github.com/KosmosOgKaos/kogk/blob/master/packages/gatsby-starter/src/components/templates/Page/Page.js
SEO.seoPropertiesToProps = properties => {
  return properties
    .filter(({ type }) => type)
    .reduce((acc, { type, value }) => {
      if (type === 'title') {
        acc.title = value
      } else if (type === 'description') {
        acc.description = value
      } else if (type.match(/^og:/)) {
        acc.og = { ...(acc.og || {}), [type]: value }
      } else {
        acc.meta = { ...(acc.meta || {}), [type]: value }
      }
      return acc
    }, {})
}
export default SEO
